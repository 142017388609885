import React from 'react';
import copy from 'copy-to-clipboard';

import FICE_LOGO from './images/logo.png'
import FICE_GIF from './images/FICE.gif'
import FICE_FOOTER from './images/fice-footer.png'
import BT_LOGO from './images/bt_footer.png'
import PRICE_RISK from './images/risco-valor.png'

function App() {
  const handleCopy = () => {
    copy('VIPFICE')
    window.open('https://bit.ly/35p8lU1', '_blank')
  }

  return (
    <div className="page">
      <div className="container">
        <div style={{ width: '100%' }}>
          <img src={FICE_LOGO} alt="FICE" style={{ margin: '12px 0' }} />
        </div>
        <div className="flex-50-100 " >
          <div className="title">
            <h1>
              Que tal ingressos GRÁTIS?
            </h1>
          </div>
          <div className="gif-area">
            <img src={FICE_GIF} alt="FICE" className="fice-gif" />
            <h2>
              23 de novembro de 2019
            </h2>
          </div>
        </div>
        <div className="flex-50-100 " style={{ position: 'relative' }}>
          <div className="discounts">
            <p>Valor do ingresso</p>
            <h2 className="price">
              <span>R$</span> 390,00
            </h2>
            <img src={PRICE_RISK} alt="Preço" className="risk" />
          </div>
          <div className="discounts">
            <p>Valor com cupom de desconto</p>
            <h2 className="price">
              GRÁTIS
            </h2>
          </div>
          <div className="code-area">
            <p>Utilize este cupom no site da <b>FICE</b> e garanta seu ingresso na faixa</p>
            <div className="code">
              <h2>VIPFICE</h2>
            </div>
          </div>
          <div className="button-area">
            <button className="button" onClick={handleCopy}>
              Copiar código e visitar o site
            </button>
          </div>
        </div>
      </div>
      <div className="footer">
        <h3>
          Parceria entre
        </h3>
        <img src={BT_LOGO} alt="Big Tester" />
        <img src={FICE_FOOTER} alt="FICE" />
      </div>
    </div>
  );
}

export default App;
